import { Component, OnInit } from '@angular/core';
import { UserService } from '../user.service';
import { AuthService } from '../auth.service';
import { CategoryService } from '../category.service';
import { TaskService } from '../task.service';
import { Observable, combineLatest, merge } from 'rxjs';
import { Router, ActivatedRoute  } from '@angular/router';
import { AppService } from '../app.service';
import { Task } from '../task';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  tasks: Observable<Task[]>;

  constructor(private userService: UserService, private authService: AuthService, private catService: CategoryService,
    private taskService: TaskService, private appService: AppService, private router: Router, private route: ActivatedRoute) {

    this.route.paramMap.subscribe(pm => {
      this.appService.params.next(pm);
    });

    merge(this.appService.catId$, this.appService.userId$).subscribe(change => {
      this.loadTasks();
    });

    // this.loadTasks();
  }

  ngOnInit() {
  }

  loadTasks() {
    if (this.appService.categoryId)
      this.tasks = this.taskService.categoryTasks(this.appService.userId, this.appService.categoryId);
    else
      this.tasks = this.taskService.openTasks(this.appService.userId);
  }

  markDone(taskId: string) {
    this.taskService.markDone(this.appService.user.uid, this.appService.userId, taskId);
  }

  isLate(task) {
    return this.taskService.isLate(task);
  }

  edit(task) {
    this.router.navigate(['/task', task]);
  }

  category(task) {
    if (task.category) {
      if (this.appService.catMap[task.category]) {
        return this.appService.catMap[task.category].name;
      }
    }
    return null;
  }

  isAdmin$() {
    console.log('isA ' + this.appService.isAdmin$);
    return this.appService.isAdmin$;
  }

  userId$() {
    return this.appService.userId$;
  }
}