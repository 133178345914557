import { Component, OnInit } from '@angular/core';
import { AppService } from '../app.service';
import { AuthService } from '../auth.service';
import { UserService } from '../user.service';
import { Router } from '@angular/router';
import { Observable, combineLatest } from 'rxjs';
import { map, mergeMap, single, merge } from 'rxjs/operators';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})
export class NavbarComponent implements OnInit {
  url: string;
  userName$: Observable<string>;

  constructor(private appService: AppService, private authService: AuthService, 
    private userService: UserService, private router:Router) {
    this.url = router.url;

    this.userName$ = combineLatest(this.userService.users$, this.appService.userId$)
      .pipe(map(([users, userId]) => users.find(u => u.id == userId).name));
  }

  ngOnInit() {
  }

  selectUser(newUserId: string) {
    this.appService.selectUser(newUserId);
  }

  selectCategory(newCategoryId: string) {
    this.appService.selectCategory(newCategoryId);
  }

  logout() {
    this.authService.logout();
    this.router.navigate(['/login']);
  }

  user() { return this.appService.user; }
  catMap() { return this.appService.catMap || {}; }
  categoryId() { return this.appService.categoryId; }
  users$() { return this.userService.users$; }
  categories$() { return this.appService.categories$; }
  curUserName() { return this.appService.curUserName; }
  userId$() { return this.appService.userId$; }
  userId() { return this.appService.userId; }
}
