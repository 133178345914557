import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';
import { User } from 'firebase';
import { first } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  constructor(private afAuth: AngularFireAuth, private db: AngularFirestore) { }
/*
  user(): Promise<User> {
    return this.afAuth.user.pipe(first()).toPromise();
  }
*/
  user(): Observable<User> {
    return this.afAuth.user;
  }

  logout() {
    this.afAuth.auth.signOut();
  }
}
