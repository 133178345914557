import { Component, OnInit, ViewChild } from '@angular/core';
// import * as $ from 'jquery';
// import 'fullcalendar';
import { CalendarComponent } from 'ng-fullcalendar';
import { OptionsInput } from 'fullcalendar';
import { AuthService } from '../auth.service';
import { TaskService } from '../task.service';
import { Observable } from 'rxjs';
import { Router, ParamMap, ActivatedRoute } from '@angular/router';
import * as addDays from 'date-fns/add_days';
import { User } from 'firebase';
import { firestore } from 'firebase';
import { Timestamp } from 'rxjs/internal/operators/timestamp';
import { Task } from '../task';

@Component({
  selector: 'app-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.css' ]
})
export class TCalendarComponent implements OnInit {
  calendarOptions: OptionsInput;
  users: any;
  user: User;
  userId: string;
  tasks: Task[];
  events: any[];
  colorMap = {DONE: '#80d680', NOT_DONE: '#dddddd', NOT_CHECKED: '#d6d680',
    LATE: '#d68080'}

  @ViewChild(CalendarComponent) ucCalendar: CalendarComponent;

  constructor(private taskService: TaskService, private route: ActivatedRoute,
    private authService: AuthService, private router: Router) {
    this.authService.user().subscribe(user => {this.user = user;});

    this.route.paramMap.subscribe(params => {
      this.userId = params.get('userId');
      this.taskService.allTasks(this.userId).subscribe(tasks => {
        this.tasks = tasks;
        this.events = tasks.map(task => {
          var status = taskService.isLate(task) ? 'LATE' : task.status;
          return {title: task.name, task: task,
            start: task.dueDate.toDate(), 
            allDay: true, color: this.colorMap[status]};
        });
      });
    });
  }

  ngOnInit() {
    // $('#calendar').fullCalendar({});
    this.calendarOptions = {
      editable: true,
      eventLimit: false,
      header: {
        left: 'prev,next today',
        center: 'title',
        right: 'month,agendaWeek,agendaDay,listMonth'
      },
      events: []
    };
  }

  eventClick(eventDetail) {
    this.router.navigate(['/task', this.userId, eventDetail.event.task.id]);
  }

  updateEvent(eventDetail) {
    var task = eventDetail.event.task;
    var dueDate = task.dueDate.toDate();
    this.taskService.update(this.userId, task.id, 
      {dueDate: firestore.Timestamp.fromDate(addDays(dueDate, eventDetail.duration.days()))}, 
      this.user.uid);
  }
}
