import { Component, OnInit } from '@angular/core';
import { AppService } from '../app.service';
import { ActivityService } from '../activity.service';
import { Observable, combineLatest, forkJoin } from 'rxjs';
import { UserService } from '../user.service';
import { map, mergeMap, merge, tap, shareReplay, switchMap } from 'rxjs/operators';
import { TaskService } from '../task.service';
import { Router, ActivatedRoute } from '@angular/router';
import { AngularFirestoreDocument, DocumentChangeAction } from '@angular/fire/firestore';
import { Task } from '../task';
import { addIdMap } from '../utils';
import { Action } from 'rxjs/internal/scheduler/Action';

@Component({
  selector: 'app-activity',
  templateUrl: './activity.component.html',
  styleUrls: ['./activity.component.css']
})
export class ActivityComponent implements OnInit {
  activities$: Observable<any[]>;
  tasks$: Observable<Map<string, Task>>; //: Observable<Map<string, Observable<Task>>>;
  taskMap: Map<string, any>;
  // tasks: any[];

  constructor(private appService: AppService, private activityService: ActivityService,
    private userService: UserService, private taskService: TaskService, private router: Router,
    private route: ActivatedRoute) {
    
    this.route.paramMap.subscribe(pm => {
      this.appService.params.next(pm);
    });

    this.appService.userId$.subscribe(userId => {  
      console.log('userId$ sub');
      this.activities$ = activityService.activities$(userId).pipe(shareReplay(1));

      const taskIds$: Observable<string[]> = this.activities$.pipe(map(activities => {
        return Array.from(new Set(activities.map(act => act.taskId)));
      }));

      this.tasks$ = taskIds$.pipe(
        switchMap(taskIds => combineLatest(taskIds.map(taskId => taskService.get(userId, taskId).snapshotChanges().pipe(
          map(a => {
            const data = a.payload.data();
            const id = a.payload.id;
            return {id, ...data};
          })
        )))),
        map(tasks => new Map(tasks.map(task => [task.id, task] as [string, Task]))));

    });
  }

  ngOnInit() {
  }

  users$() {
    console.log(this.userService.users$);
    return this.userService.users$;
  }

  user$(userId) {
    return this.userService.users$.pipe(map(users => users.find(u => u.id == userId)));
  }

  userId$() { return this.appService.userId$; }
}
