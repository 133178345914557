import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Task } from './task';
import { AngularFirestore } from '@angular/fire/firestore';
import { dataWithId } from './utils';

@Injectable({
  providedIn: 'root'
})
export class ActivityService {

  constructor(private db: AngularFirestore) { }

  activities$(userId: string): Observable<any[]> {
    return this.db.collection(`users/${userId}/activity`).valueChanges();
  }
}
