import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { AuthService } from '../auth.service'
import { CategoryService } from '../category.service'
import { TaskService } from '../task.service'
import { Router, ParamMap, ActivatedRoute } from '@angular/router';
import * as startOfToday from 'date-fns/start_of_today';
import * as get_date from 'date-fns/get_date'
import * as get_month from 'date-fns/get_month'
import * as get_year from 'date-fns/get_year'
import { Task } from '../task';
import { AppService } from '../app.service';

@Component({
  selector: 'app-task',
  templateUrl: './task.component.html',
  styleUrls: ['./task.component.css']
})
export class TaskComponent implements OnInit {
  categories: Observable<any>;
  user: firebase.User;
  taskForm: FormGroup;
  task: Task;
  userId: string;
  recentTasks: Observable<Task[]>;

  constructor(private formBuilder: FormBuilder, private route: ActivatedRoute,
    private authService: AuthService, private appService: AppService,
    private catService: CategoryService,
    private taskService: TaskService, private router: Router) {
      authService.user().subscribe(user => {this.user = user;});
  
      this.doAsyncStuff();
      var tzoffset = (new Date()).getTimezoneOffset() * 60000; //offset in milliseconds
      var localISOTime = (new Date(startOfToday().getTime() - tzoffset)).toISOString().slice(0, -1);
      this.taskForm = this.formBuilder.group({
          'name' : [null, Validators.required],
          'category' : [null],
          'status' : ["NOT_DONE", Validators.required],
          'dueDate' : [{
            day: get_date(localISOTime),
            month: get_month(localISOTime) + 1,
            year: get_year(localISOTime)}, 
            Validators.required],
          'requiresCheck' : [false]
        });            

        this.taskForm.get('category').valueChanges.subscribe(categoryId => {
          this.recentTasks = this.taskService.recentCategoryTasks(this.userId, categoryId);
        });
    }

  ngOnInit() {
  }

  async doAsyncStuff() {
    this.route.paramMap.subscribe(params => {
      var taskId = params.get('taskId');
      this.userId = params.get('userId');
      console.log(taskId + "," + this.userId);
      if (taskId) {
        this.taskService.get(this.userId, taskId).valueChanges().subscribe(task => {
          this.task = task;
          console.log(task + "," + taskId + "," + this.userId);
          task.id = taskId;
          var dueDate = task.dueDate.toDate();
          if (task.category === undefined) {
            task.category = null;
          }
          if (task.requiresCheck === undefined)
            task.requiresCheck = null;
          this.taskForm.setValue({name: task.name, category: task.category, status: task.status, requiresCheck: task.requiresCheck,
            dueDate: {month: get_month(dueDate) + 1, day: get_date(dueDate), year: get_year(dueDate)}});;
        });  
      } else {
        this.task = null;
      }
      this.categories = this.catService.categories(this.userId);
    });
  }

  prepareForm() {
    const form = this.taskForm.value;
    form.dueDate = new Date(form.dueDate.year,
      form.dueDate.month - 1, form.dueDate.day);

    if (!form.category)
      delete form['category'];

    return form;
  }

  create() {
    var form = this.prepareForm();
    this.taskService.create(this.userId, form, this.user.uid);

    this.router.navigate(['/home', this.userId]);
  }

  update() {
    var form = this.prepareForm();

    this.taskService.update(this.userId, this.task.id, form, this.user.uid);

    this.router.navigate(['/home', this.userId]);
  }

  delete() {
    this.taskService.delete(this.userId, this.task.id, this.user.uid);
    this.router.navigate(['/home', this.userId]);
  }

  useRecentName(rt) {
    event.preventDefault();
    this.taskForm.get('name').setValue(rt.name);
  }
}
