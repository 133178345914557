import { Injectable } from '@angular/core';
import { UserService } from './user.service';
import { AuthService } from './auth.service';
import { CategoryService } from './category.service';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { combineLatest, Observable, Subject } from 'rxjs';
import { User } from 'firebase';
import { TaskService } from './task.service';
import { shareReplay, map, switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AppService {
  user: User;
  isAdmin$: Observable<boolean>;
  curUserName: string;
  userId: string;
  categories$: Observable<any>;
  catMap: Map<string, any>;
  categoryId: string;
  private userIdS: Subject<string> = new Subject();
  userId$: Observable<string> = this.userIdS.asObservable().pipe(shareReplay(1));
  private catIdS: Subject<string> = new Subject();
  catId$: Observable<string> = this.catIdS.asObservable();
  private curUserS: Subject<string> = new Subject();
  curUser$: Observable<string> = this.curUserS.asObservable();

  params: Subject<ParamMap> = new Subject();

  constructor(private userService: UserService, private authService: AuthService, 
    private catService: CategoryService, private taskService: TaskService, private route: ActivatedRoute) {

      const combined = combineLatest(this.userService.users$, this.params, this.authService.user());
      combined.subscribe(([users, params, user]) => {
        console.log('params.userId = ' + params.get('userId'));
        console.log(params);

        if (!params.get('userId')) {
          this.curUserS.next(users[0].id);
        } else {
          this.user = user;
          this.userId = params.get('userId');
  
          console.log('*' + user.uid + "," + this.userId);
  
          this.isAdmin$ = userService.isAdmin$(user.uid, this.userId);
    
          this.curUserName = users.find(u => u.id == user.uid).name;  

          this.userIdS.next(params.get('userId'));

        }
      });

      this.categories$ = this.params.pipe(switchMap(params => {
        return this.catService.categories(params.get('userId'));
      }));
      this.categories$.subscribe(categories => {
        this.catMap = new Map(categories.map(c => [c.id, c]));
      });
    }

  selectUser(newUserId: string) {
    this.curUserS.next(newUserId);
  }

  selectCategory(newCategoryId: string) {
    this.categoryId = newCategoryId;
    this.catIdS.next(newCategoryId);
  }
}