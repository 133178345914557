import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { dataWithId } from './utils'

@Injectable({
  providedIn: 'root'
})
export class CategoryService {

  constructor(private db: AngularFirestore) { }

  categories(userId): Observable<any> {
    return dataWithId(this.db.collection(`users/${userId}/categories`,
      ref => ref.orderBy('name')));
  }
}
