import { Component } from '@angular/core';
import { AppService } from './app.service'
import { Router, ActivatedRoute, UrlSegment } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'trascat';
  url: UrlSegment[];

  constructor(private appService: AppService, private router: Router,
    private route: ActivatedRoute) {
    route.url.subscribe(urlSegs => {
      this.url = urlSegs;
    });

    this.appService.curUser$.subscribe(userId => {
      var path = this.url[0].path;
      if (path == "")
        path = "home";

      router.navigate([path, userId]);
    });

  }
}
