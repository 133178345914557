import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './auth.guard'
import { HomeComponent } from './home/home.component'
import { LoginComponent } from './login/login.component'
import { ActivityComponent } from './activity/activity.component'
import { TaskComponent } from './task/task.component';
import { TCalendarComponent } from './calendar/calendar.component';

const routes: Routes = [
  { path: '', redirectTo: 'home', pathMatch: 'full' },
  { path: 'home', component: HomeComponent, canActivate: [AuthGuard] },
  { path: 'home/:userId', component: HomeComponent, canActivate: [AuthGuard] },
  { path: 'login', component: LoginComponent },
  { path: 'activity/:userId', component: ActivityComponent },
  { path: 'task/:userId', component: TaskComponent },
  { path: 'task/:userId/:taskId', component: TaskComponent },
  { path: 'calendar/:userId', component: TCalendarComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
