import { map } from 'rxjs/operators';
import { AngularFirestoreCollection } from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { firestore } from 'firebase';

const addIdMap = map((actions: any[]) => actions.map(a => {
  const data = a.payload.doc.data();
  const id = a.payload.doc.id;
  return {id, ...data};
}));

function dataWithId(col: AngularFirestoreCollection): Observable<any[]> {
  return col.snapshotChanges().pipe(addIdMap);
}

export {dataWithId, addIdMap};
