import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { dataWithId } from './utils';
import { map, share, shareReplay } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  users$: Observable<any[]> = dataWithId(this.db.collection('users')).pipe(shareReplay(1));

  constructor(private db: AngularFirestore) { }

  isAdmin$(potentialAdminId, subjectUserId): Observable<boolean> {
    return this.db.collection('users').doc(subjectUserId)
      .collection('access').doc(potentialAdminId).get().pipe(map(doc =>
        doc.exists
      ), shareReplay(1));
  }
}
