import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';
import { firestore } from 'firebase';
import { UserService } from './user.service';
import { dataWithId } from './utils';
import { Observable, forkJoin } from 'rxjs';
import { first, take } from 'rxjs/operators';
import * as isBefore from 'date-fns/is_before';
import * as startOfDay from 'date-fns/start_of_day';
import { Task } from './task'

@Injectable({
  providedIn: 'root'
})
export class TaskService {

  constructor(private db: AngularFirestore, private userService: UserService) { }

  openTasks(userId: string): Observable<Task[]> {
    return dataWithId(this.db.collection(`users/${userId}/tasks`,
      ref => ref.where("status", ">", "DONE").orderBy('status').orderBy('dueDate')));
  }

  allTasks(userId: string): Observable<Task[]> {
    return dataWithId(this.db.collection(`users/${userId}/tasks`));
  }

  markDone(curUserId:string, taskUserId:string, taskId: string) {
    var taskRef = this.db.doc(`users/${taskUserId}/tasks/${taskId}`);

    var obs = forkJoin(taskRef.get().pipe(first()), this.userService.isAdmin$(curUserId, taskUserId).pipe(first()));
    
    obs.subscribe(([task, isAdmin]) => {
      var status;
      if (task.data().requiresCheck && !isAdmin) {
        status = 'NOT_CHECKED'
      } else {
        status = 'DONE';
      }  
      this.db.collection(`users/${taskUserId}/activity`).add({
        userId: curUserId, 
        taskId: task.id, 
        action: 'STATUS',
        when: firestore.FieldValue.serverTimestamp(),
        status: task.data().status});

      taskRef.update({status: status});
    });
  }

  create(userId: string, data: Task, curUserId: string) {
    this.db.collection(`users/${userId}/tasks`).add(data).then(task => {
      this.db.collection(`users/${userId}/activity`).add({
        userId: curUserId, 
        taskId: task.id, 
        action: 'CREATE',
        when: firestore.FieldValue.serverTimestamp()});
    });
  }

  get(userId, taskId): AngularFirestoreDocument<Task>  {
    return this.db.doc(`users/${userId}/tasks/${taskId}`)
  }

  update(userId: string, taskId: string, data: Task, curUserId: string) {
    this.db.doc(`users/${userId}/tasks/${taskId}`).update(data);
    this.db.collection(`users/${userId}/activity`).add({
      userId: curUserId, 
      taskId: taskId, 
      action: 'UPDATE',
      when: firestore.FieldValue.serverTimestamp()});
  }

  delete(userId: string, taskId: string, curUserId: string) {
    this.db.doc(`users/${userId}/tasks/${taskId}`).delete();
    this.db.collection(`users/${userId}/activity`).add({
      userId: curUserId, 
      taskId: taskId, 
      action: 'DELETE',
      when: firestore.FieldValue.serverTimestamp()});
  }

  categoryTasks(userId:string, categoryId: string): Observable<Task[]> {
    return dataWithId(this.db.collection(`users/${userId}/tasks`,
      ref => ref.where("category", "==", categoryId).orderBy('dueDate')));
  }

  isLate(task) {
    return task.status == 'NOT_DONE' && isBefore(task.dueDate.toDate(), startOfDay(new Date()));
  }

  recentCategoryTasks(userId:string, categoryId: string): Observable<Task[]> {
    return dataWithId(this.db.collection(`users/${userId}/tasks`,
      ref => ref.where("category", "==", categoryId).orderBy('dueDate'))).pipe(take(5));
  }
}